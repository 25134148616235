var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7c6c1b3987fc437bd905a2636bf9da1f05a18a20"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// このファイルは、クライアントのSentryの初期化を設定します。
// ここに追加した設定は、ユーザがブラウザでページをロードするたびに使用されます。
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const TRACES_SAMPLE_RATE = 0.1
const REPLAYS_SESSION_SAMPLE_RATE = 0.1
const REPLAYS_ON_ERROR_SAMPLE_RATE = 1.0

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration({ tracingOrigins: ['*'] }), Sentry.replayIntegration()],
	// Performance Monitoring
	tracesSampleRate: TRACES_SAMPLE_RATE,
	// Session Replay
	replaysSessionSampleRate: REPLAYS_SESSION_SAMPLE_RATE,
	replaysOnErrorSampleRate: REPLAYS_ON_ERROR_SAMPLE_RATE,

	beforeSend(event) {
		// PII（個人識別情報）をイベントから削除
		if (event.user) {
			// biome-ignore lint/performance/noDelete: <explanation>
			delete event.user.ip_address
		}
		return event
	},
})

// コメント：
// - tracesSampleRate: 10%のトランザクションをキャプチャ
// - replaysSessionSampleRate: 10%のセッションをキャプチャ
// - replaysOnErrorSampleRate: エラーが発生したセッションは100%キャプチャ
// - beforeSend: センシティブな情報（IPアドレスなど）をイベントから削除
